var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-chrome",
  body: function iconChrome() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 15 15\">" + "<g>" + "<path d=\"M7.500,15.000 C7.362,15.000 7.228,14.987 7.091,14.979 C7.091,14.979 10.839,8.753 10.839,8.753 C10.839,8.753 10.771,8.715 10.771,8.715 C10.912,8.335 11.000,7.929 11.000,7.500 C11.000,6.519 10.594,5.635 9.944,5.000 C9.944,5.000 14.564,5.000 14.564,5.000 C14.841,5.783 15.000,6.622 15.000,7.500 C15.000,11.642 11.642,15.000 7.500,15.000 ZM8.000,4.050 C7.835,4.027 7.671,4.000 7.500,4.000 C5.570,4.000 4.007,5.562 4.001,7.490 C4.001,7.490 0.753,4.242 0.753,4.242 C1.966,1.734 4.527,0.000 7.500,0.000 C10.377,0.000 12.873,1.622 14.131,4.000 C14.131,4.000 8.000,4.000 8.000,4.000 C8.000,4.000 8.000,4.050 8.000,4.050 ZM5.000,7.500 C5.000,6.119 6.119,5.000 7.500,5.000 C8.881,5.000 10.000,6.119 10.000,7.500 C10.000,8.016 9.843,8.496 9.575,8.894 C9.575,8.894 9.572,8.899 9.572,8.899 C9.123,9.563 8.363,10.000 7.500,10.000 C6.814,10.000 6.194,9.723 5.742,9.276 C5.742,9.276 5.765,9.253 5.765,9.253 C5.765,9.253 5.512,9.001 5.512,9.001 C5.195,8.582 5.000,8.066 5.000,7.500 ZM5.031,9.980 C5.031,9.980 5.035,9.983 5.035,9.983 C5.035,9.983 5.035,9.983 5.035,9.983 C5.668,10.611 6.538,11.000 7.500,11.000 C7.807,11.000 8.100,10.948 8.384,10.874 C8.384,10.874 5.991,14.848 5.991,14.848 C2.572,14.150 -0.000,11.125 -0.000,7.500 C-0.000,6.728 0.117,5.983 0.334,5.283 C0.334,5.283 5.020,9.969 5.020,9.969 C5.024,9.972 5.028,9.976 5.031,9.980 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;